import { Container, Flex, Heading, Text, useToast, useDisclosure } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import Balancer from 'react-wrap-balancer'
import { useAccount } from 'wagmi'

import {
  ConnectWallet,
  RetrieveDialogue,
  SendToLoginDialogue,
  ShadowCollections,
  BulkRetrieveDialogue,
  BulkSendToLoginDialogue,
} from '@/components'
import { IBulkSelectToken, IOnSelectBulkTokens, SelectedToken } from '@/components/types'
import { useIsClient } from '@/hooks'

const Home = () => {
  const isClient = useIsClient()
  const { isConnected } = useAccount()
  const toast = useToast({
    variant: 'subtle',
    isClosable: true,
    position: 'top-right',
  })

  const [selectedTokenSend, setselectedTokenSend] = useState<SelectedToken | null>(null)
  const [selectedTokenRetrieve, setselectedTokenRetrieve] = useState<SelectedToken | null>(null)
  const [selectedBulkTokens, setselectedBulkTokens] = useState<IBulkSelectToken | null>(null)
  const {
    isOpen: isBulkRetrieveOpen,
    onOpen: openBulkRetrieve,
    onClose: closeBulkRetrieve,
  } = useDisclosure()
  const {
    isOpen: isBulkSendToLoginOpen,
    onOpen: openBulkSendToLogin,
    onClose: closeBulkSendToLogin,
  } = useDisclosure()

  const onClickRetrieve = useCallback((selectedToken: SelectedToken) => {
    setselectedTokenRetrieve(selectedToken)
  }, [])

  const onClickSend = useCallback((selectedToken: SelectedToken) => {
    setselectedTokenSend(selectedToken)
  }, [])

  const onClickBulkSend = useCallback(() => {
    openBulkSendToLogin()
  }, [openBulkSendToLogin])

  const onClickBulkRetrieve = useCallback(() => {
    openBulkRetrieve()
  }, [openBulkRetrieve])

  const onSelectBulkTokens = useCallback(
    (selectedToken: IOnSelectBulkTokens) => {
      if (!selectedBulkTokens) {
        return setselectedBulkTokens({
          ...selectedToken,
          tokens: [{ tokenId: selectedToken.tokenId, parsedURI: selectedToken.parsedURI }],
        })
      }
      if (selectedBulkTokens?.collection !== selectedToken.collection) {
        return toast({
          title: 'Error',
          description: "You can't select tokens from different collections",
          status: 'error',
        })
      }
      if (selectedBulkTokens?.status !== selectedToken.status) {
        return toast({
          title: 'Error',
          description: "You can't select tokens with different statuses",
          status: 'error',
        })
      }
      if (selectedBulkTokens.tokens.find(({ tokenId }) => tokenId === selectedToken.tokenId)) {
        return setselectedBulkTokens((prev) => {
          if (!prev) return null
          const tokens = prev.tokens.filter(
            ({ tokenId }) => Number(tokenId) !== selectedToken.tokenId
          )
          if (tokens.length === 0) return null
          return {
            ...selectedToken,
            tokens,
          }
        })
      }
      return setselectedBulkTokens((prev) => {
        if (!prev) return null
        return {
          ...prev,
          tokens: [
            ...prev.tokens,
            { tokenId: selectedToken.tokenId, parsedURI: selectedToken.parsedURI },
          ],
        }
      })
    },
    [selectedBulkTokens, toast]
  )

  if (!isConnected && isClient) {
    return <ConnectWallet />
  }

  return (
    <>
      <SendToLoginDialogue
        selectedToken={selectedTokenSend}
        onClose={() => setselectedTokenSend(null)}
      />
      <RetrieveDialogue
        selectedToken={selectedTokenRetrieve}
        onClose={() => setselectedTokenRetrieve(null)}
      />
      <BulkSendToLoginDialogue
        selectedTokens={selectedBulkTokens}
        onSelectBulkTokens={onSelectBulkTokens}
        isOpen={isBulkSendToLoginOpen}
        onClose={() => closeBulkSendToLogin()}
      />
      <BulkRetrieveDialogue
        selectedTokens={selectedBulkTokens}
        onSelectBulkTokens={onSelectBulkTokens}
        isOpen={isBulkRetrieveOpen}
        onClose={() => closeBulkRetrieve()}
      />

      <Flex
        textAlign="center"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        my="12"
        mx="auto"
        maxW="xl"
        // position="relative"
      >
        {/* <Box
          rounded="full"
          bgGradient="linear(to-r, brand.500, pink.500)"
          h={100}
          opacity={0.6}
          sx={{ aspectRatio: 1 }}
          zIndex="0"
          position="absolute"
          bottom="calc(100% - 150px)"
          maxW="lg"
          w="full"
          filter="blur(64px)"
        /> */}
        <Heading mt="8" zIndex="1">
          <Balancer>FoolProof Token Dashboard</Balancer>
        </Heading>
        <Text mt="3" zIndex="1" color="slate.600">
          <Balancer>
            FoolProof Token (FPT) offers unparalleled security and control over digital assets by
            creating a 1:1 linked token to prove ownership without risking ownership.
          </Balancer>
        </Text>
      </Flex>

      <Container
        my={{ base: 8, md: 12 }}
        p={{ base: 4, md: 12 }}
        maxW="4xl"
        rounded={'md'}
        w="full"
        marginX="auto"
        borderWidth={{ base: 0, md: 1 }}
      >
        <ShadowCollections
          onClickRetrieve={onClickRetrieve}
          onClickSend={onClickSend}
          onClickBulkRetrieve={onClickBulkRetrieve}
          onSelectBulkTokens={onSelectBulkTokens}
          onClickBulkSend={onClickBulkSend}
          selectedBulkTokens={selectedBulkTokens}
        />
      </Container>
    </>
  )
}

export default Home
